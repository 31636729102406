import { useRef } from "react";
import Image from "next/image";
import Link from "next/link";

const NavHeading = ({ children }) => (
  <h4 className="text-sm font-semibold">{children}</h4>
);

const linkClasses = "text-sm text-grey-dark";

const Footer = () => {
  const year = useRef(new Date().getFullYear());

  return (
    <footer className="bg-grey-lightest">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between justify-items-center p-16 md:pl-0">
          <Link href="/">
            <a className="font-serif text-xl font-bold tracking-tighter">
              <Image
                src="/images/logo.svg"
                alt="NashDev Logo"
                width="110"
                height="25"
              />
            </a>
          </Link>
        </div>

        <div className="flex justify-between border-t border-grey-light border-1 py-8">
          <p className="text-xs text-grey-dark">
            &copy; {year.current} NashDev. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
