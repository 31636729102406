import Link from "next/link";
import Image from "next/image";

const Navbar = () => {
  return (
    <header className="p-4">
      <div className="hidden md:block container mx-auto">
        <div className="flex justify-between items-center">
          <Link href="/slack">
            <a className="font-serif text-xl font-bold tracking-tighter">
              <Image
                src="/images/logo.svg"
                alt="NashDev Logo"
                width="110"
                height="25"
              />
            </a>
          </Link>
        </div>
      </div>

      <div className="md:hidden">
        <Link href="/">
          <a className="font-serif text-xl font-bold tracking-tighter">
            <Image
              src="/images/logo.svg"
              alt="NashDev Logo"
              width="65"
              height="28"
            />
          </a>
        </Link>
      </div>
    </header>
  );
};

export default Navbar;
