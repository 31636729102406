import { useEffect, useState } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { useRouter } from "next/router";
import Banner from "components/atoms/banner/banner";

const authRoutes = ["/login", "/login/recover", "/login/reset", "/signup"];

const Layout = ({ children }) => {
  const { route } = useRouter();
  const [navbar, setNavbar] = useState(true);

  useEffect(() => {
    {
      /**
       * TODO: This technique causes jitter on page load (CLS)
       * so we'll need to polish that up
       */
    }
    if (authRoutes.includes(route)) {
      setNavbar(false);
    } else {
      setNavbar(true);
    }
  }, [route]);

  return (
    <>
      {navbar && <Navbar />}
      <main className="p-4">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
