import Link from "next/link";
import React, { useState } from "react";
import { useCookies } from "react-cookie";

const Banner = ({ headlineSmall, headline, action = "/" }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "NashDevAlphaDismiss",
  ]);
  const [dismiss, setDismiss] = useState(false);
  const onDissmiss = (e) => {
    e.preventDefault();
    setCookie("NashDevAlphaDismiss", true);
    setDismiss(true);
  };

  if (dismiss || cookies.NashDevAlphaDismiss) {
    return null;
  }
  return (
    <div className="bg-black">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap flex-col md:flex-row">
          <div className="w-0 flex-1 flex items-center flex-col md:flex-row">
            <span className="flex p-2 rounded-lg bg-indigo-800">
              {/* Heroicon name: outline/speakerphone */}
              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
            </span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="md:hidden">{headlineSmall}</span>
              <span className="hidden md:inline">{headline}</span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-auto sm:order-2 md:mt-0">
            <Link href={action}>
              <a className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50">
                Learn more
              </a>
            </Link>
          </div>
          <div className="order-3 flex-shrink-0 md:order-2 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              onClick={onDissmiss}
            >
              <span className="sr-only">Dismiss</span>
              {/* Heroicon name: outline/x */}
              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
