import Router from "next/router";
import Head from "next/head";

import * as Sentry from "@sentry/node";
import { pageview } from "utils/gtag";
import Layout from "components/layout";

import "tailwindcss/tailwind.css";

/**
 * Binding events
 */

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", (url) => {
  pageview(url);
  NProgress.done();
});
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, ctx, pageProps, err }) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
      </Head>

      <Layout>
        <Component {...pageProps} err={err} />
      </Layout>
    </>
  );
}

export default MyApp;
