export const pageview = (url) => {
  window.dataLayer.push({
    event: "pageview",
    page: {
      path: url,
    },
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ event, ...data }) => {
  window.dataLayer.push({
    event,
    ...data,
  });
};

export const loginSuccess = (userId) => {
  event({
    event: "login",
    user_id: userId,
  });
};

export const loginFailed = (userId) => {
  event({
    event: "login_failed",
  });
};

export const signupSuccess = (userId) => {
  event({
    event: "signup",
  });
};

export const signupFailed = (userId) => {
  event({
    event: "signup_failed",
  });
};

export const setUserId = (userId) => {
  window.dataLayer.push({
    user_id: userId,
  });
};
